 export const companyName ={id : 1,nameAr:" نظام كالوري كلوب", nameEN:"Calorie Club System"}
export const version="1.4.3";

export const api='https://chef-road.com/api/';
 //export const api='http://127.0.0.1:8000/api/';




export const imageAPI = [];

for (let i = 1; i <= 154; i++) {
  imageAPI.push({
    id: i,
    imgurl: "https://chef-road.com/public/images/productimgs/" + i + ".png"
  });
}






